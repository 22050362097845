
         @import "config.scss";
      
.item {
   position: relative;
   background-size: cover;
   background-position: center;
   background-repeat: repeat;
   width: 50px;
   height: 50px;
   margin: 0 15px 10px 0;

   img {
      width: 50px;
      height: 50px;
   }

   &:hover,
   &:active,
   &:focus {
      @include opacity(1);
      @include transition(all 0.25s ease);
      cursor: pointer;
   }

   &--active {
      background-color: #000000;
      border-radius: 5px;

      img {
         opacity: 0.9;
      }
   }

   &--disabled {
      img {
         opacity: 0.7;
      }
      .icon {
         left: 0;
      }

      &:hover,
      &:active,
      &:focus {
         cursor: not-allowed;
      }
   }

   .icon {
      fill: $color-red;
      position: absolute;
      width: 50px;
      height: 50px;
   }
}
