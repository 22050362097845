
         @import "config.scss";
      
.wrapper {
   margin-bottom: 30px;
   position: relative;
   .title {
      @extend %title-with-border;
   }

   .item {
      display: block !important;
      margin: auto;
      list-style: none;
      padding: 0 10px;
   }
   .carousel {
      width: 100%;
      margin: auto;
   }
}
