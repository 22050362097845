
         @import "config.scss";
      
.valorations {
   @extend %valoracion-body-text;

   .media {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
      padding: 0 10px;
      width: calc(100% - 4px);
      min-height: 50px;
      margin-left: 2px;
      margin-bottom: 30px;
      .title {
         @extend %valoracion-media;
         display: flex;
         flex-direction: column;
         justify-content: center;
         margin: 0;
      }
   }

   .comment__review {
      border-bottom: 1px solid $color-title-border;
      word-break: break-word;
      p {
         @extend %valoracion-comment;
      }
      p,
      h4 {
         margin: 10px 0;
      }
      div {
         display: flex;
         align-items: center;
         gap: 1rem;
      }
      .comment__user {
         @extend %valoracion-username;
         font-weight: 100;
      }
   }
}
