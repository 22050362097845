
         @import "config.scss";
      
.container {
   @extend %container;
   @extend %containerBreakpoint;
   display: grid;
   grid-gap: 50px;

   .gallery {
      position: relative;
   }

   .wrapper {
      display: grid;
      grid-gap: 10px;

      .wrapperTop {
         display: flex;
         align-items: flex-start;
         justify-content: space-between;
      }

      .sku {
         @extend %label;
      }

      .price {
         display: flex;
      }

      .badges {
         display: flex;
         align-items: flex-start;
         flex-direction: column;
      }

      .titleQuantity {
         @extend %title-with-border;
      }

      .buttonWrapper {
         margin-top: 15px;

         .row {
            display: flex;
            flex-direction: row;
            align-items: center;

            .addButton {
               flex: 1;
               margin: 0;
            }
         }
         .messageSize {
            @extend %body-2;
            display: block;
            color: $color-red;
            margin-top: 10px;
         }
      }
   }

   .carousel {
      max-height: fit-content;
      position: relative;
   }

   .propertiesDescription,
   .carousel {
      align-self: center;
   }

   .carousel {
      max-width: $breakpoint-view;
      width: 100%;
      overflow: hidden;
   }

   .propertiesDescription {
      display: grid;
   }
}

.wrapperCheckout {
   @extend %sticky;
   margin: 0 10px;
   bottom: 8px;
   margin-bottom: 10px;
   z-index: 1000;
}

// Desktop
@include media('>tablet') {
   .wrapperCheckout {
      display: none;
      z-index: 0;
   }
}

@include media('<=tablet') {
   .wrapperCheckout {
      z-index: 0;
   }
}

// Desktop
@include media('>desktop') {
   .container {
      grid-template-columns: repeat(2, 1fr);

      .wrapper {
         .wrapperTop {
            align-items: center;
         }

         .quantity {
            display: block;
         }
      }

      .propertiesDescription,
      .carousel {
         grid-column: 1/3;
         align-self: center;
         justify-self: center;
         width: 100%;
      }
   }
}

.productBadges {
   padding: 0;
}
