
         @import "config.scss";
      
.review-container {
   display: flex;
   align-items: center;
   @extend %valoracion-body-text;
   .number {
      @extend %valoracion-media;
      margin-right: 2px;
      margin-bottom: 0;
   }
}
.stars-review {
   display: block;
}
.star-empty {
   color: #a1a1a1;
}
.errorMessage {
   @extend %error;
   display: block;
   border-color: $color-red;
   margin-top: 4px;
   font-weight: 700;
}
.half-star {
   color: #ffd100;
   display: inline;
   div {
      z-index: 100;
      position: relative;
      display: inline-block;
      .star-empty {
         position: absolute;
         top: 0;
         z-index: -1;
         left: 0;
      }
   }
}
.star-full {
   color: #ffd100;
}
.scoring {
   &:hover {
      color: #ffd0006e;
   }
}
