
         @import "config.scss";
      
.container {
   display: flex;
   height: 440px;

   .placeholder {
      margin: 0 10px;

      &:nth-child(1),
      &:nth-child(2) {
         display: block;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
         display: none;
      }
   }
}

@include media('>=500px') {
   .container {
      .placeholder {
         &:nth-child(3) {
            display: block;
         }
      }
   }
}

@include media('>=700px') {
   .container {
      .placeholder {
         &:nth-child(4) {
            display: block;
         }
      }
   }
}

@include media('>=1000px') {
   .container {
      .placeholder {
         &:nth-child(5) {
            display: block;
         }
      }
   }
}
