
         @import "config.scss";
      
.wrapper {
   .description {
      p {
         @extend %description;
         white-space: pre-wrap;
      }
   }

   .icon {
      width: $icon-size;
      height: $icon-size;
   }
}

// Desktop
@include media('>desktop') {
   .wrapper {
      width: 100%;
      margin: 0 auto;
   }
}
