
         @import "config.scss";
      
.wrapper {
   .title {
      @extend %title-with-border;
   }
   .list {
      display: flex;
      flex-wrap: wrap;
   }
   .placeholder {
      min-height: 60px;
   }
}
