
         @import "config.scss";
      
.breadcrumb {
   margin: 1vw;
   display: none;
   .link {
      color: $color-link;
   }
   .item {
      display: flex;
      gap: 10px;
   }
   .chevron {
      color: $color-black-200;
   }
}
.mobile {
   display: none;
   @include media('<=tablet') {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
   }
}
.desktop {
   @include media('>=tablet') {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
   }
}
