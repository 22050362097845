
         @import "config.scss";
      
@import '../../styles/config/variables';
.accordion__section {
   display: flex;
   flex-direction: column;
}
.accordion {
   background-color: $color-white;
   color: $color-grey-200;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border: none;
   outline: none;
   transition: background-color 0.6s ease;
   border-bottom: 1px solid $color-title-border;
   margin: 10px 0;
}
.accordion:hover,
.active {
   background-color: $color-white-50;
}
.accordion__title {
   @extend %subtitle-2;
   color: $color-title;
   margin: 10px 0;
}
.accordion__icon {
   margin-left: auto;
   transition: transform 0.6s ease;
   color: #333;
}
.rotate {
   transform: rotate(180deg);
}
.accordion__content {
   background-color: $color-white;
   overflow: hidden;
   transition: max-height 0.6s ease;
}
