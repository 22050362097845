
         @import "config.scss";
      
.wrapper {
   .table {
      @include borderRadius($radius-table);
      border: $border-table solid $color-table-border;

      table {
         @extend %table;
         @include borderRadius($radius-table);
         border-collapse: collapse;
         overflow: hidden;
         width: 100%;

         tbody {
            th,
            td {
               padding: 16px;
               width: 50%;
            }

            th {
               font-weight: 700;
            }

            tr:nth-child(odd) {
               th {
                  background-color: $color-table-cell-1-bg;
               }
               td {
                  background-color: $color-table-cell-2-bg;
               }
            }

            tr:nth-child(even) {
               th {
                  background-color: $color-table-cell-2-bg;
               }
               td {
                  background-color: $color-table-cell-3-bg;
               }
            }
         }
      }
   }
}

// Desktop
@include media('>desktop') {
   .wrapper {
      width: 100%;
      margin: 0 auto;
   }
}
